import { Component, OnInit, AfterContentInit, Input, ContentChildren, QueryList, HostBinding, EmbeddedViewRef, TemplateRef, Inject, forwardRef, ViewContainerRef, ChangeDetectorRef, OnDestroy, ElementRef, EventEmitter, Output, ViewChild, AfterViewChecked } from '@angular/core';
import { ZAppDevTabPanel } from './tabPanel.component';
import { zAppDevComponentStatus } from '../component-status';
import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import { BlockableUI, PrimeTemplate } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { zAppDevComponentSize } from '../component-size';
import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';
import { transformStyleToObject } from '@framework/common';

let idx: number = 0;

@Component({
  selector: 'zapp-tabView',
  templateUrl: './tabView.component.html',
  styleUrls: ['./tabView.component.less'],
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevTabViewComponent }]

})
export class zAppDevTabViewComponent extends zAppDevBaseComponent implements AfterContentInit, AfterViewChecked, BlockableUI {
  @Input() orientation: string = 'top';

  tabContainerThemeOptions: ThemeOptions.TabContainerThemeOptions;
  tabHeaderThemeOptions: ThemeOptions.TabHeaderThemeOptions;
  tabContentThemeOptions: ThemeOptions.TabContentThemeOptions;
  tabPageThemeOptions: ThemeOptions.TabPageThemeOptions;

  @Input() size: zAppDevComponentSize = '';

  @Input() status: zAppDevComponentStatus = 'global';
  //@Input()
  //set status(s: zAppDevComponentStatus) {
  //    this._status = s;
  //    this.updateClass();
  //}
  //get status(): zAppDevComponentStatus {
  //    return this._status;
  //}

  //@HostBinding('class') TabClass: string;
  @HostBinding('class')
  get hostClasses(): string {
    return this.hostClassesArray.concat(this.styleClassesArray).join(' ');
  }

  TabHeaderClass: string;

  @Input() styleClass: string;

  @Input() controlClose: boolean;

  @ViewChild('navbar') navbar: ElementRef;

  @ViewChild('inkbar') inkbar: ElementRef;

  @ContentChildren(ZAppDevTabPanel) tabPanels: QueryList<ZAppDevTabPanel>;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @Output() activeIndexChange: EventEmitter<number> = new EventEmitter();

  initialized: boolean;

  tabs: ZAppDevTabPanel[];

  _activeIndex: number;

  preventActiveIndexPropagation: boolean;

  tabChanged: boolean;

  constructor(public el: ElementRef, public cd: ChangeDetectorRef, private themeService: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
    this.tabContainerThemeOptions = this.themeService.getTabContainerThemeOptions("top");
    this.tabHeaderThemeOptions = this.themeService.getTabHeaderThemeOptions("top");
    this.tabContentThemeOptions = this.themeService.getTabContentThemeOptions("top");
    this.tabPageThemeOptions = this.themeService.getTabPageThemeOptions("top");
  }

  ngAfterContentInit() {
    this.initTabs();

    this.tabPanels.changes.subscribe(_ => {
      this.initTabs();
    });

    // this.updateClass();
  }

  ngAfterViewChecked() {
    if (this.tabChanged) {
      this.updateInkBar();
      this.tabChanged = false;
    }
  }

  initTabs(): void {
    this.tabs = this.tabPanels.toArray();
    let selectedTab: ZAppDevTabPanel = this.findSelectedTab();
    if (!selectedTab && this.tabs.length) {
      if (this.activeIndex != null && this.tabs.length > this.activeIndex)
        this.tabs[this.activeIndex].selected = true;
      else
        this.tabs[0].selected = true;

      this.tabChanged = true;
    }

    this.cd.markForCheck();
  }

  open(event: Event, tab: ZAppDevTabPanel) {
    if (tab.disabled) {
      if (event) {
        event.preventDefault();
      }
      return;
    }

    if (!tab.selected) {
      let selectedTab: ZAppDevTabPanel = this.findSelectedTab();
      if (selectedTab) {
        selectedTab.selected = false
      }

      this.tabChanged = true;
      tab.selected = true;
      let selectedTabIndex = this.findTabIndex(tab);
      this.preventActiveIndexPropagation = true;
      this.activeIndexChange.emit(selectedTabIndex);
      this.onChange.emit({ originalEvent: event, index: selectedTabIndex });
    }

    if (event) {
      event.preventDefault();
    }
  }

  close(event: Event, tab: ZAppDevTabPanel) {
    if (this.controlClose) {
      this.onClose.emit({
        originalEvent: event,
        index: this.findTabIndex(tab),
        close: () => {
          this.closeTab(tab);
        }
      }
      );
    }
    else {
      this.closeTab(tab);
      this.onClose.emit({
        originalEvent: event,
        index: this.findTabIndex(tab)
      });
    }

    event.stopPropagation();
  }

  closeTab(tab: ZAppDevTabPanel) {
    if (tab.disabled) {
      return;
    }
    if (tab.selected) {
      this.tabChanged = true;
      tab.selected = false;
      for (let i = 0; i < this.tabs.length; i++) {
        let tabPanel = this.tabs[i];
        if (!tabPanel.closed && !tab.disabled) {
          tabPanel.selected = true;
          break;
        }
      }
    }

    tab.closed = true;
  }

  findSelectedTab() {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].selected) {
        return this.tabs[i];
      }
    }
    return null;
  }

  findTabIndex(tab: ZAppDevTabPanel) {
    let index = -1;
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i] == tab) {
        index = i;
        break;
      }
    }
    return index;
  }

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }

  @Input() get activeIndex(): number {
    return this._activeIndex;
  }

  set activeIndex(val: number) {
    this._activeIndex = val;
    if (this.preventActiveIndexPropagation) {
      this.preventActiveIndexPropagation = false;
      return;
    }

    if (this.tabs && this.tabs.length && this._activeIndex != null && this.tabs.length > this._activeIndex) {
      this.findSelectedTab().selected = false;
      this.tabs[this._activeIndex].selected = true;
    }
  }

  updateInkBar() {
    if (this.navbar == null) {
      return;
    }

    let tabHeader = DomHandler.findSingle(this.navbar.nativeElement, 'li.p-highlight');
    this.inkbar.nativeElement.style.width = DomHandler.getWidth(tabHeader) + 'px';
    this.inkbar.nativeElement.style.left = DomHandler.getOffset(tabHeader).left - DomHandler.getOffset(this.navbar.nativeElement).left + 'px';
  }

  rootClass() {
    return (this.tabContainerThemeOptions ?? [])[this.variation]?.Classes?.Global ?? '';
  }

  statusClass() {
    const extraClasses =
      this.tabContainerThemeOptions[this.variation]?.Classes?.Roles[this.status] + " "
      + this.tabHeaderThemeOptions[this.variation]?.Classes?.Roles[this.status] + " "
      + this.tabContentThemeOptions[this.variation]?.Classes?.Roles[this.status] + " "
      + this.tabPageThemeOptions[this.variation]?.Classes?.Roles[this.status];
    return extraClasses;
  }

  private updateClass() {
    //if (this.tabContainerThemeOptions != null) {
    //  this.TabClass = [
    //    this.styleClass,
    //    this.statusClass(),
    //  ].join(' ');
    //  this.TabHeaderClass = this.tabHeaderThemeOptions[this._variation].HeaderNavClass;
    //  this.tabs.forEach(element => {
    //    element.headerItemClass = this.tabHeaderThemeOptions[this._variation].HeaderNavItemClass;
    //    element.headerItemLinkClass = this.tabHeaderThemeOptions[this._variation].HeaderNavItemLinkClass;
    //  });
    //}
  }
}
