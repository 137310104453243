import {Injectable} from "@angular/core";
import {lastValueFrom} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {take} from "rxjs/operators";
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndexSearchService {
  constructor(private httpClient: HttpClient) {
  }

  async search(index: string, q: string, matchCase: boolean, partialMatch: boolean): Promise<IndexSearchResult[]> {
    const request$ = this.httpClient.get<IndexSearchResult[]>(
      environment.appUrl + "_DomainSearchEngine/Search",
      {
        params: {index: index, q: q, matchCase: matchCase, partialMatch: partialMatch}
      }
    ).pipe(take(1));
    return await lastValueFrom(request$);
  }
}

export interface IndexSearchResult {
  ClassName: string,
  Id: string,
  Relevance: number,
  PrimaryLabel: string,
  SecondaryLabel: string,
  RouterLink: string,
  Icon: string
}
