import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IThemeSevice } from '../../../theme/interfaces/theme.interface';
import { IDataListTextResources } from "./IDataListTextResources";
import { ListControlResourcesServiceBase } from './resources.interface';


// TODO Update when language changes
@Injectable({ providedIn: 'root' })
export class ListControlResourcesService extends ListControlResourcesServiceBase {
  public readonly textResources: IDataListTextResources;

  public readonly dataTablesResources = {
    decimal: "",
    emptyTable: "No data available in table",
    info: "Showing _START_ to _END_ of _TOTAL_ entries",
    infoEmpty: "Showing 0 to 0 of 0 entries",
    infoFiltered: "(filtered from _MAX_ total entries)",
    infoPostFix: "",
    thousands: ",",
    lengthMenu: "Show _MENU_ entries",
    loadingRecords: "Loading...",
    processing: "<b>Peos</b>",
    search: "Search:",
    zeroRecords: "No matching records found",
    paginate: {
      first: "First",
      last: "Last",
      next: "Next",
      previous: "Previous",
    },
    aria: {
      sortAscending: ": activate to sort column ascending",
      sortDescending: ": activate to sort column descending",
    },
    buttons: {
      colvis: "Column visibility",
      copy: "Copy",
      copyTitle: "Copy to clipboard",
      copySuccess: {
        1: "Copied one row to clipboard",
        _: "Copied %d rows to clipboard",
      },
    },
    select: {
      rows: {
        _: "%d rows selected",
        0: "",
        1: "1 row selected",
      },
    },
  };

  constructor(protected readonly translate: TranslateService,
    protected readonly themeservice: IThemeSevice) {
    super();
    this.textResources = this.initTextResources();

    // Overwrite the default datatables resources
    this.dataTablesResources.emptyTable = this.textResources.NoResults;
    this.dataTablesResources.info = this.textResources.Info;
    this.dataTablesResources.infoEmpty = "";
    this.dataTablesResources.lengthMenu = this.textResources.PageSize;
    this.dataTablesResources.loadingRecords = this.textResources.Loading;
    this.dataTablesResources.processing = `<div class="loader-container"><div class="loader"></div></div>`; // TODO this.textResources.Processing;
    this.dataTablesResources.search = "";
    this.dataTablesResources.paginate.first = this.textResources.First;
    this.dataTablesResources.paginate.last = this.textResources.Last;
    this.dataTablesResources.paginate.next = this.textResources.Next;
    this.dataTablesResources.paginate.previous = this.textResources.Previous;
    this.dataTablesResources.buttons.colvis = this.textResources.ColumnVisibility;
    this.dataTablesResources.buttons.copy = this.textResources.Copy;
    this.dataTablesResources.buttons.copyTitle = this.textResources.CopyTitle;
    this.dataTablesResources.buttons.copySuccess["1"] = this.textResources.CopySuccessSingle;
    this.dataTablesResources.buttons.copySuccess["_"] = this.textResources.CopySuccessMulti;
    this.dataTablesResources.select.rows["1"] = this.textResources.OneRowSelected;
    this.dataTablesResources.select.rows["_"] = this.textResources.MulitpleRowsSelected;
    this.icons = this.getIcons();
  }

  getIcons(): any {
    const options = this.themeservice.getListThemeOptions();

    return {
      ...this.icons,
      ...options['Standard'].Icons,
    };
  }

  private initTextResources(): IDataListTextResources { 
    var textResources = this.getListResources();
    return textResources;
  }

  private getListResources(): IDataListTextResources {
    return {
      Actions: this.translate.instant("RES_DATALIST_Actions"),
      ActiveView: this.translate.instant("RES_DATALIST_ActiveView"),
      AddFilter: this.translate.instant("RES_LIST_FILTERS_AddFilter"),
      AggregateColor: this.translate.instant("RES_LIST_EXPORT_AggregateColor"),
      All: this.translate.instant("RES_DATALIST_All"),
      And: this.translate.instant("RES_LIST_FILTERS_RowOperatorTypes_And"),
      Apply: this.translate.instant("RES_LIST_FILTERS_Apply"),
      ApplyQuickFilter: this.translate.instant("RES_LIST_FILTERS_ApplyQuickFilter"),
      Ascending: this.translate.instant("RES_LIST_PREFERENCES_SortingType_ASC"),
      AverageHeader: this.translate.instant("RES_LIST_EXPORT_AverageHeader"),
      CalculateAverage: this.translate.instant("RES_LIST_AGGREGATORS_CalculateAverage"),
      CalculateCount: this.translate.instant("RES_LIST_AGGREGATORS_CalculateCount"),
      CalculateSum: this.translate.instant("RES_LIST_AGGREGATORS_CalculateSum"),
      Cancel: this.translate.instant("RES_LIST_VIEWS_Cancel"),
      ClearAggregators: this.translate.instant("RES_LIST_AGGREGATORS_ClearAll"),
      ClearAll: this.translate.instant("RES_LIST_FILTERS_ClearAll"),
      ClearQuickFilter: this.translate.instant("RES_LIST_FILTERS_ClearQuickFilter"),
      ClearSearch: this.translate.instant("RES_DATALIST_ClearSearch"),
      Column: this.translate.instant("RES_LIST_FILTERS_Column_Header"),
      ColumnExport: this.translate.instant("RES_LIST_EXPORT_Column"),
      ColumnVisibility: this.translate.instant("RES_DATALIST_BUTTONS_ColumnVisibility"),
      CommonActions: this.translate.instant("RES_LIST_TOOLBAR_CommonActions"),
      Copy: this.translate.instant("RES_DATALIST_BUTTONS_Copy"),
      CopySuccessMulti: this.translate.instant("RES_DATALIST_BUTTONS_CopySuccessMulti"),
      CopySuccessSingle: this.translate.instant("RES_DATALIST_BUTTONS_CopySuccessSingle"),
      CopyTitle: this.translate.instant("RES_DATALIST_BUTTONS_CopyTitle"),
      CountHeader: this.translate.instant("RES_LIST_EXPORT_CountHeader"),
      Criteria: this.translate.instant("RES_LIST_FILTERS_FilterValue_Header"),
      DeleteConfirmation: this.translate.instant("RES_DATALIST_VIEWS_DeleteConfirmation"),
      DeleteCurrentView: this.translate.instant("RES_LIST_VIEWS_Remove"),
      DeleteCurrentViewCommand: this.translate.instant("RES_LIST_VIEWS_Delete"),
      DeleteCurrentViewConfirmation: this.translate.instant("RES_LIST_VIEWS_RemovePrompt"),
      DeleteView: this.translate.instant("RES_DATALIST_VIEWS_DeleteView"),
      Descending: this.translate.instant("RES_LIST_PREFERENCES_SortingType_DESC"),
      DeselectAllPageRecordsText: this.translate.instant("RES_LIST_DeselectAllPageRecordsText"),
      DeselectAllRecordsPromptText: this.translate.instant("RES_LIST_DeselectAllRecordsPromptText"),
      DeselectAllRecordsText: this.translate.instant("RES_DATALIST_DeselectAllRecordsText"),
      DialogCancelButton: this.translate.instant("RES_LIST_VIEWS_Cancel"),
      DialogOkButton: this.translate.instant("RES_LIST_VIEWS_Ok"),
      DisplayColumns: this.translate.instant("RES_LIST_EXPORT_DisplayColumns"),
      DisplayGroupItems: this.translate.instant("RES_LISTFORM_DisplayGroupItems"),
      DownloadCsv: this.translate.instant("RES_LIST_Import_DownloadCsv"),
      EqualTo: this.translate.instant("RES_LIST_FILTERS_Operators_EqualTo"),
      ErrorDescription: this.translate.instant("RES_LIST_Import_ErrorDescription"),
      ErrorDetails: this.translate.instant("RES_LIST_Import_ErrorDetails"),
      ErrorMessage: this.translate.instant("RES_LIST_Import_ErrorMessage"),
      EvenColor: this.translate.instant("RES_LIST_EXPORT_EvenColor"),
      Export: this.translate.instant("RES_LIST_EXPORT_ExportOK"),
      ExportOnlyGroups: this.translate.instant("RES_LIST_EXPORT_ExportOnlyGroups"),
      ExportRange: this.translate.instant("RES_LIST_EXPORT_PrintRange"),
      ExportRange100: this.translate.instant("RES_LIST_EXPORT_ExportRange_Top100Pages"),
      ExportRangeAll: this.translate.instant("RES_LIST_EXPORT_ExportRange_AllPages"),
      ExportRangeCurrent: this.translate.instant("RES_LIST_EXPORT_ExportRange_CurrentPage"),
      ExportTitle: this.translate.instant("RES_DATALIST_ExportTitle"),
      ExportType: this.translate.instant("RES_LIST_EXPORT_ExportTo"),
      FailedRecords: this.translate.instant("RES_LIST_Import_FailedRecords"),
      False: this.translate.instant("RES_LIST_FILTERS_False"),
      FileName: this.translate.instant("RES_LIST_EXPORT_FileName"),
      FiltersApplied: this.translate.instant("RES_LISTFORM_FiltersApplied"),
      FiltersPopUpTitle: this.translate.instant("RES_LIST_FILTERS_DialogTitle"),
      FiltersTooltip: this.translate.instant("RES_LIST_TOOLBAR_FilterButtonTooltip"),
      First: this.translate.instant("RES_DATALIST_PAGER_First"),
      FirstPageTooltip: this.translate.instant("RES_LIST_PAGER_FirstPage"),
      GetGroupsClosed: this.translate.instant("RES_LISTFORM_GetGroupsClosed"),
      GrandAverage: this.translate.instant("RES_LIST_AGGREGATORS_GrandAverage"),
      GrandCount: this.translate.instant("RES_LIST_AGGREGATORS_GrandCount"),
      GrandTotal: this.translate.instant("RES_LIST_AGGREGATORS_GrandTotal"),
      GreaterThan: this.translate.instant("RES_LIST_FILTERS_Operators_GreaterThan"),
      GreaterThanOrEqualTo: this.translate.instant("RES_LIST_FILTERS_Operators_GreaterThanOrEqualTo"),
      GroupColor: this.translate.instant("RES_LIST_EXPORT_GroupColor"),
      Grouping: this.translate.instant("RES_DATALIST_Grouping"),
      GroupingOrder: this.translate.instant("RES_LIST_TOOLBAR_GroupingOrder"),
      HasNoValue: this.translate.instant("RES_LIST_FILTERS_Operators_HasNoValue"),
      HasValue: this.translate.instant("RES_LIST_FILTERS_Operators_HasValue"),
      HeaderColor: this.translate.instant("RES_LIST_EXPORT_HeaderColor"),
      HideQuickFilters: this.translate.instant("RES_LIST_TOOLBAR_HideQuickFilters"),
      Import: this.translate.instant("RES_LIST_Import_Import"),
      ImportDownload: this.translate.instant("RES_LIST_Import_Import"),
      ImportedRecords: this.translate.instant("RES_LIST_Import_ImportedRecords"),
      ImportEncoding: this.translate.instant("RES_DATALIST_ImportDataEncoding"),
      ImportError: this.translate.instant("RES_DATALIST_ImportResultErrors"),
      ImportErrorList: this.translate.instant("RES_DATALIST_ImportResultErrorList"),
      ImportResult: this.translate.instant("RES_LIST_Import_ImportResults"),
      ImportResults: this.translate.instant("RES_LIST_Import_ImportResults"),
      ImportSuccess: this.translate.instant("RES_DATALIST_ImportResultSuccess"),
      ImportUpload: this.translate.instant("RES_DATALIST_UploadImportFile"),
      IncludeGridLines: this.translate.instant("RES_LIST_EXPORT_IncludeGridLines"),
      Info: this.translate.instant("RES_DATALIST_Info"),
      InvalidViewName: this.translate.instant("RES_DATALIST_VIEWS_InvalidViewName"),
      IsDefault: this.translate.instant("RES_LIST_VIEWS_IsDefault"),
      IsViewDefault: this.translate.instant("RES_DATALIST_VIEWS_IsViewDefault"),
      Items: this.translate.instant("RES_LIST_PAGER_Items"),
      Last: this.translate.instant("RES_DATALIST_PAGER_Last"),
      LastPageTooltip: this.translate.instant("RES_LIST_PAGER_LastPage"),
      LessThan: this.translate.instant("RES_LIST_FILTERS_Operators_LessThan"),
      LessThanOrEqualTo: this.translate.instant("RES_LIST_FILTERS_Operators_LessThanOrEqualTo"),
      Like: this.translate.instant("RES_LIST_FILTERS_Operators_Like"),
      Loading: this.translate.instant("RES_DATALIST_Loading"),
      MakeDefault: this.translate.instant("RES_LIST_VIEWS_MakeDefault"),
      MakeDefaultConfirmation: this.translate.instant("RES_LIST_VIEWS_MakeDefaultConfirmation"),
      MaxSelectedRowsLimitationMessage: this.translate.instant("RES_DATALIST_MaxSelectedRowsLimitationMessage"),
      MaxSelectedRowsLimitationTitle: this.translate.instant("RES_DATALIST_MaxSelectedRowsLimitationTitle"),
      MergedGroupLevels: this.translate.instant("RES_DATALIST_MergedGroupLevels"),
      MoveColumnLeft: this.translate.instant("RES_LIST_MoveColumnLeft_Tooltip"),
      MoveColumnRight: this.translate.instant("RES_LIST_MoveColumnRight_Tooltip"),
      MulitpleRowsSelected: this.translate.instant("RES_DATALIST_MulitpleRowsSelected"),
      Next: this.translate.instant("RES_DATALIST_PAGER_Next"),
      NextPage: this.translate.instant("RES_LIST_PAGER_NextPage"),
      NextPageTooltip: this.translate.instant("RES_LIST_PAGER_NextPage"),
      NoFiltersDefined: this.translate.instant("RES_LIST_FILTERS_NoFilterDefined"),
      NoResults: this.translate.instant("RES_LISTFORM_NORESULTS_NoResults"),
      NotEqualTo: this.translate.instant("RES_LIST_FILTERS_Operators_NotEqualTo"),
      OddColor: this.translate.instant("RES_LIST_EXPORT_OddColor"),
      Of: this.translate.instant("RES_LIST_PAGER_Of"),
      Ok: this.translate.instant("RES_LIST_VIEWS_Ok"),
      OneRowSelected: this.translate.instant("RES_DATALIST_OneRowSelected"),
      Operator: this.translate.instant("RES_LIST_FILTERS_Operators_Header"),
      Or: this.translate.instant("RES_LIST_FILTERS_RowOperatorTypes_Or"),
      Order: this.translate.instant("RES_LIST_PREFERENCES_Order"),
      OverwriteCurrentView: this.translate.instant("RES_LIST_VIEWS_Overwrite"),
      Page: this.translate.instant("RES_LIST_TOOLBAR_Page"),
      PageAverage: this.translate.instant("RES_LIST_AGGREGATORS_PageAverage"),
      PageCount: this.translate.instant("RES_LIST_AGGREGATORS_PageCount"),
      Pages: this.translate.instant("RES_LIST_PAGE_Pages"),
      PageSize: this.translate.instant("RES_DATALIST_PageSize"),
      PageTotal: this.translate.instant("RES_LIST_AGGREGATORS_PageTotal"),
      PerPage: this.translate.instant("RES_LIST_PAGER_PerPage"),
      PortraitOrientation: this.translate.instant("RES_LIST_EXPORT_PortraitOrientation"),
      PredefinedView: this.translate.instant("RES_LIST_TOOLBAR_PredefinedView"),
      PreferedEncoding: this.translate.instant("RES_LIST_Import_PreferedEncoding"),
      PreferencesPopUpTitle: this.translate.instant("RES_LIST_PREFERENCES_DialogTitle"),
      PreferencesTooltip: this.translate.instant("RES_LIST_TOOLBAR_PreferencesButtonTooltip"),
      Previous: this.translate.instant("RES_DATALIST_PAGER_Previous"),
      PreviousPage: this.translate.instant("RES_LIST_PAGER_PreviousPage"),
      PrevPageTooltip: this.translate.instant("RES_LIST_PAGER_PreviousPage"),
      PrevStateTooltip: this.translate.instant("RES_LISTFORM_PrevStateTooltip"),
      Processing: this.translate.instant("RES_DATALIST_Processing"),
      QuickFilters: this.translate.instant("RES_DATALIST_QuickFilters"),
      Range: this.translate.instant("RES_LIST_FILTERS_Operators_Range"),
      In: this.translate.instant("RES_LIST_FILTERS_Operators_In"),
      Rearrange: this.translate.instant("RES_LIST_PREFERENCES_Rearrange"),
      Records: this.translate.instant("RES_DATALIST_Records"),
      Refresh: this.translate.instant("RES_DATALIST_Refresh"),
      RefreshTooltip: this.translate.instant("RES_LIST_TOOLBAR_RefreshButtonTooltip"),
      RequiredFiltersMissingMessage: this.translate.instant("RES_LIST_RequiredFiltersMissingMessage"),
      RequiredFiltersMissingTitle: this.translate.instant("RES_LIST_RequiredFiltersMissingTitle"),
      Reset: this.translate.instant("RES_DATALIST_Reset"),
      ResetConfirmation: this.translate.instant("RES_LIST_TOOLBAR_ResetConfrmation"),
      ResetTooltip: this.translate.instant("RES_LIST_TOOLBAR_RefreshResetButtonTooltip"),
      RowNumber: this.translate.instant("RES_LIST_Import_RowNumber"),
      RowOperator: this.translate.instant("RES_LIST_FILTERS_RowOperator_Header"),
      SaveCurrentView: this.translate.instant("RES_LIST_VIEWS_Save"),
      SaveCurrentViewNameAlert: this.translate.instant("RES_LIST_VIEWS_NameAlert"),
      SaveView: this.translate.instant("RES_DATALIST_VIEWS_SaveView"),
      Search: this.translate.instant("RES_LIST_TOOLBAR_Search"),
      SelectAllPageRecordsText: this.translate.instant("RES_LIST_SelectAllCheckBox_Tooltip"),
      SelectAllRecordsPromptText: this.translate.instant("RES_LIST_FILTERS_SelectAll"),
      SelectAllRecordsText: this.translate.instant("RES_DATALIST_SelectAllRecordsText"),
      SelectionActions: this.translate.instant("RES_DATALIST_SelectionActions"),
      ShowQuickFilters: this.translate.instant("RES_LIST_TOOLBAR_ShowQuickFilters"),
      SortASC: this.translate.instant("RES_LIST_SortASC_Tooltip"),
      SortDESC: this.translate.instant("RES_LIST_SortDESC_Tooltip"),
      Sorting: this.translate.instant("RES_LIST_PREFERENCES_Sorting"),
      SortOrder: this.translate.instant("RES_LIST_PREFERENCES_SortOrder"),
      SubGroups: this.translate.instant("RES_LISTFORM_SubGroups"),
      SumHeader: this.translate.instant("RES_LIST_EXPORT_SumHeader"),
      TotalNonGroupCount: this.translate.instant("RES_LIST_EXPORT_TotalNonGroupCount"),
      True: this.translate.instant("RES_LIST_FILTERS_True"),
      Unsort: this.translate.instant("RES_LIST_UnSort_Tooltip"),
      UploadCsv: this.translate.instant("RES_LIST_Import_UploadCsv"),
      View: this.translate.instant("RES_DATALIST_VIEWS_View"),
      ViewName: this.translate.instant("RES_LIST_VIEWS_Name"),
      Views: this.translate.instant("RES_DATALIST_VIEWS_Views"),
      Visible: this.translate.instant("RES_LIST_PREFERENCES_Visible"),
      VisibleExport: this.translate.instant("RES_LIST_EXPORT_Visible"),
    };
  }

  icons = {
    refresh: "fa fa-refresh",
    reset: "fa fa-repeat",
    group: "fa fa-table",
    export: "fa fa-download",
    view: "fa fa-eye",
    hideShow: "fa fa-table",
    remove: "fa fa-trash",
    filter: "fa fa-filter",
    search: "fa fa-search",
    aggregator: "fa fa-dashboard",
    menuItem: "fa fa-circle",
  };
}
