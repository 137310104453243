import { Injectable } from "@angular/core";
import { Subject, catchError, lastValueFrom, map, of } from "rxjs";
import { Joove } from "@framework/core/Joove";
import { PermissionService } from '@framework/security/services/permission.service';
import { ThemeService } from "../../@theming/theme.service";
import * as moment from "moment";
import * as Http from '@framework/http';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  constructor(
    private permissionService: PermissionService,
    private themeService: ThemeService,
    private httpClient: HttpClient) { }

  context$: Subject<Joove.IContext> = new Subject<Joove.IContext>();
  private context: Joove.IContext;
  private currentLanguage: string | null = null;

  update(context: Joove.IContext) {

    if (context.theme !== undefined) {
      this.themeService.setMainTheme(context.theme);
    }
    this.context = { ...this.context, ...context };
    window._context = this.context;
    this.context$.next(this.context);
    this.permissionService.updatePermissions(context.currentUserPermissions);

    if (context?.currentLanguage?.language != null && context.currentLanguage.language.length > 0) {
      moment.locale(context.currentLanguage?.language);
    }

    // check if language has changed and reload page
    if (context.currentLanguage?.language == null || context.currentLanguage?.language.length <= 0) {
      return;
    }

    if (this.currentLanguage == null) {
      this.currentLanguage = context.currentLanguage.language;
      return;
    }

    if (this.currentLanguage === context.currentLanguage?.language) {
      return;
    }

    this.currentLanguage = context.currentLanguage.language;
  }

  async changeLanguage(lang: { Id?: number; Name?: string; Code?: string; }) {
    const resp = await lastValueFrom(this.httpClient
      .post<{ Id?: number; Name?: string; Code?: string; }>(environment.appUrl + `InternalUtilities/SetLanguage`, lang, {
        observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        })
      })
      .pipe(map(Http.responseFilter), catchError((err, caught) => {
        return of(null);
      })));

    this.update({ ...window._context, currentLanguage: resp });
  }

}
