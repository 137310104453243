import {NgModule} from '@angular/core';
import {zAppSearchModalComponent} from './SearchModal.component';
import {zAppDevModalModule} from "@framework/components/Modal/modal.module";
import {zAppDevTextBoxModule} from "@framework/components/TextBox/textbox.module";
import {zAppDevCheckBoxModule} from "@framework/components/CheckBox/checkbox.module";
import {zAppDevTabContainerModule} from "@framework/components/TabContainer/tabcontainer.module";
import {zAppDevGridModule} from "@framework/components/Grid/grid.module";
import {RouterLink} from "@angular/router";
import {zAppDevButtonModule} from "@framework/components/Button/button.module";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {zAppDevIconismModule} from "@framework/components/Iconism/iconism.module";
import {zAppDevLabelModule} from "@framework/components/Label/label.module";

@NgModule({
  imports: [
    zAppDevModalModule,
    zAppDevTextBoxModule,
    zAppDevCheckBoxModule,
    zAppDevTabContainerModule,
    zAppDevGridModule,
    zAppDevButtonModule,
    zAppDevIconismModule,
    CommonModule,
    FormsModule,
    RouterLink,
    TranslateModule,
    zAppDevLabelModule
  ],
  declarations: [zAppSearchModalComponent],
  exports: [zAppSearchModalComponent],
  providers: []
})
export class zAppDevBreadcrumbModule {
}
