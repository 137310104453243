import { NgModule } from '@angular/core';
import { CalculatedExpressionDirective } from './directives/calculated-expression.directive';
import { ConditionFormattingDirective } from './directives/condition-formatting.directive';
import { DataValidationDirective } from './directives/data-validation.directive';

@NgModule({
  declarations: [ConditionFormattingDirective, CalculatedExpressionDirective, DataValidationDirective],
  imports: [],
  exports: [ConditionFormattingDirective, CalculatedExpressionDirective, DataValidationDirective]
})
export class RuleEngineModule { }
