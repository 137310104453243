import { Component, OnInit, Input, ViewChild, ElementRef, ContentChildren, QueryList, Output, EventEmitter, ChangeDetectorRef, AfterContentInit, AfterViewChecked, Inject, forwardRef, OnDestroy, EmbeddedViewRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { BlockableUI, PrimeTemplate } from 'primeng/api';
import { zAppDevComponentStatus } from '../component-status';
import { zAppDevTabViewComponent } from './tabView.component';
import * as ThemeOptions from '../../theme/interfaces/options';
import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';
import { transformStyleToObject } from '@framework/common';

let idx: number = 0;

@Component({
  selector: 'zapp-tabPanel',
  templateUrl: './tabPanel.component.html',
  styleUrls: [],
  providers: [{ provide: CF_COMPONENT, useExisting: ZAppDevTabPanel }]
})
export class ZAppDevTabPanel extends zAppDevBaseComponent {

  @Input() closable: boolean;

  @Input() headerItemClass: string;

  @Input() headerItemLinkClass: string;

  @Input() cache: boolean = true;

  @Input() tooltip: any;

  @Input() tooltipPosition: string = 'top';

  @Input() tooltipPositionStyle: string = 'absolute';

  @Input() tooltipStyleClass: string;

  @Input() contentTemplate: TemplateRef<any>;

  @Input() headerTemplate: TemplateRef<any>;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  //@ContentChildren(PrimeTemplate) templates: QueryList<any>;

  closed: boolean;

  view: EmbeddedViewRef<any>;

  _selected: boolean;

  _disabled: boolean;

  _header: string;

  _leftIcon: string;

  _rightIcon: string;

  loaded: boolean;

  id: string = `p-tabpanel-${idx++}`;

  tabView: zAppDevTabViewComponent;

  constructor(@Inject(forwardRef(() => zAppDevTabViewComponent)) tabView, public viewContainer: ViewContainerRef, public cd: ChangeDetectorRef, protected elementRef: ElementRef) {
    super(elementRef);
    this.tabView = tabView as zAppDevTabViewComponent;
  }

  //ngAfterContentInit() {
  //  this.templates.forEach((item) => {
  //    switch (item.getType()) {
  //      case 'header':
  //        this.headerTemplate = item.template;
  //        break;

  //      case 'content':
  //        this.contentTemplate = item.template;
  //        break;

  //      default:
  //        this.contentTemplate = item.template;
  //        break;
  //    }
  //  });
  //}

  @Input() get selected(): boolean {
    return this._selected;
  }

  set selected(val: boolean) {
    if (this._selected != true && val == true) {
      this.onSelect.emit({});
    }
    
    this._selected = val;

    if (!this.loaded) {
      this.cd.detectChanges();
    }

    if (val)
      this.loaded = true;
  }

  //@Input() get disabled(): boolean {
  //  return this._disabled;
  //};

  //set disabled(disabled: boolean) {
  //  this._disabled = disabled;
  //  this.tabView.cd.markForCheck();
  //}

  @Input() get header(): string {
    return this._header;
  }

  set header(header: string) {
    this._header = header;
    this.tabView.cd.markForCheck();
  }

  @Input() get leftIcon(): string {
    return this._leftIcon;
  }

  set leftIcon(leftIcon: string) {
    this._leftIcon = leftIcon;
    this.tabView.cd.markForCheck();
  }

  @Input() get rightIcon(): string {
    return this._rightIcon;
  }

  set rightIcon(rightIcon: string) {
    this._rightIcon = rightIcon;
    this.tabView.cd.markForCheck();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.view = null;
  }
}
