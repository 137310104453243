<div *ngIf="!hidden" [ngClass]="'p-tabview p-component'" [ngStyle]="_style">
  <div class="p-tabview-nav-container">
    <div class="p-tabview-nav-content">
      <ul #navbar [class]="TabHeaderClass" class="p-tabview-nav" role="tablist">
        <ng-template ngFor let-tab [ngForOf]="tabs">
          <li role="presentation" [ngClass]="{'p-highlight': tab.selected, 'p-disabled': tab.disabled}" [ngStyle]="tab._style" [class]="tab.headerItemClass" *ngIf="!tab.closed && !tab.hidden">
            <a class="p-tabview-nav-link" [class]="tab.headerItemLinkClass"
               (click)="open($event,tab)" (keydown.enter)="open($event,tab)">
              <ng-container>
                <span class="p-tabview-left-icon" [ngClass]="tab.leftIcon" *ngIf="tab.leftIcon"></span>
                <span class="p-tabview-title">{{tab.header}}</span>
                <span class="p-tabview-right-icon" [ngClass]="tab.rightIcon" *ngIf="tab.rightIcon"></span>
              </ng-container>
              <ng-container *ngTemplateOutlet="tab.headerTemplate"></ng-container>
              <span *ngIf="tab.closable" class="p-tabview-close pi pi-times" (click)="close($event,tab)"></span>
            </a>
          </li>
        </ng-template>
        <li #inkbar class="p-tabview-ink-bar"></li>
      </ul>
    </div>
  </div>
  <div class="p-tabview-panels">
    <ng-content></ng-content>
  </div>
</div>
