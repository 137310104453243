<zapp-modal #Modal [body]="bodyModal" [header]="headerModal">
  <ng-template #headerModal>
    <label zappLabel [size]="'lg'" >
      {{ 'RES_DATALIST_Search' | translate }}
    </label>
    <zappIcon
      themeIcon="remove"
      [circularBackground]="false"
      [clickable]="true"
      style='float: right'
      [size]="'lg'"
      (click)="closeModal()">
    </zappIcon>
  </ng-template>
  <ng-template #bodyModal>
    <zappTextbox #searchBox
                 class="search-box"
                 [placeholder]="'RES_DATALIST_Search' | translate"
                 type="string"
                 [(value)]="query"
                 [liveUpdate]="true"
                 [liveChangeDelay]="300"
                 (valueChange)="search()">
    </zappTextbox>
    <zapp-tabView (activeIndexChange)="setLastActiveTab($event)" #tabView>
      <!-- Index results -->
      <zapp-tabPanel *ngIf="indexes.length > 0" [header]="'RES_MENU_SEARCH_INDEX_RESULTS' | translate" [contentTemplate]="IndexesContent">
        <ng-template #IndexesContent>
          <div class="search-indexes-checkboxes">
            <zappCheckbox [(value)]="matchCase" (valueChange)="search()">
              <label>{{ 'RES_MENU_SEARCH_MATCH_CASE' | translate }}</label>
            </zappCheckbox>
            <zappCheckbox [(value)]="partialMatch" (valueChange)="search()">
              <label>{{ 'RES_MENU_SEARCH_PARTIAL_MATCH' | translate }}</label>
            </zappCheckbox>
          </div>
          <hr>
          <zappDataTable *ngIf="indexSearchResults.length > 0" [model]="indexSearchResults" [body]="IndexesGridBody" [hoverEffect]="true">
            <ng-template #IndexesGridBody let-IndexesGridItem>
              <tr [routerLink]="IndexesGridItem.RouterLink" (click)="closeModal()" class="search-result-row">
                <td>
                  <zappIcon *ngIf="IndexesGridItem.Icon" [themeIcon]="IndexesGridItem.Icon"></zappIcon>
                </td>
                <td>{{ IndexesGridItem.PrimaryLabel }}</td>
                <td>{{ IndexesGridItem.SecondaryLabel }}</td>
              </tr>
            </ng-template>
          </zappDataTable>
          <span *ngIf="indexSearchResults.length == 0" class="nothing-to-show-label">{{ 'RES_SEARCH_MODAL_NOTHING_TO_SHOW' | translate }}</span>
        </ng-template>
      </zapp-tabPanel>
      <!-- Menu results -->
      <zapp-tabPanel *ngIf="showMenuSearch" [header]="'RES_MENU_SEARCH_SEARCH_RESULTS' | translate" [contentTemplate]="MenuContent">
        <ng-template #MenuContent>
          <zappDataTable *ngIf="menuSearchResults.length > 0" [model]="menuSearchResults" [body]="MenuGridBody" [hoverEffect]="true">
            <ng-template #MenuGridBody let-MenuGridItem>
              <tr [routerLink]="MenuGridItem.routerLink" (click)="closeModal()" class="search-result-row">
                <td *ngIf="!MenuGridItem.isFavorite" class="clickable-icon" (click)="addToFavorites($event, MenuGridItem.routerLink)">
                  <i class="pi pi-star"></i>
                </td>
                <td *ngIf="MenuGridItem.isFavorite" class="clickable-icon" (click)="removeFromFavorites($event, MenuGridItem.routerLink)">
                  <i class="pi pi-star-fill"></i>
                </td>
                <td>{{ MenuGridItem.text }}</td>
                <td>{{ MenuGridItem.path }}</td>
              </tr>
            </ng-template>
          </zappDataTable>
          <span *ngIf="menuSearchResults.length == 0" class="nothing-to-show-label">{{ 'RES_SEARCH_MODAL_NOTHING_TO_SHOW' | translate }}</span>
        </ng-template>
      </zapp-tabPanel>
      <!-- Favorites -->
      <zapp-tabPanel *ngIf="showFavorites" [header]="'RES_MENU_SEARCH_FAVORITES' | translate" [contentTemplate]="FavoritesContent">
        <ng-template #FavoritesContent>
          <zappDataTable [model]="favoriteItems" [body]="FavoritesGridBody" [hoverEffect]="true">
            <ng-template #FavoritesGridBody let-FavoritesGridItem>
              <tr [routerLink]="FavoritesGridItem.route" (click)="closeModal()" class="search-result-row">
                <td class="clickable-icon" (click)="removeFromFavorites($event, FavoritesGridItem.route)">
                  <i class="pi pi-star-fill"></i>
                </td>
                <td>{{ FavoritesGridItem.primary }}</td>
                <td>{{ FavoritesGridItem.secondary }}</td>
              </tr>
            </ng-template>
          </zappDataTable>
        </ng-template>
      </zapp-tabPanel>
      <!-- Most used -->
      <zapp-tabPanel *ngIf="showMostUsed" [header]="'RES_MENU_SEARCH_MOST_USED' | translate" [contentTemplate]="MostUsedContent">
        <ng-template #MostUsedContent>
          <zappDataTable [model]="mostUsedItems" [body]="MostUsedGridBody" [hoverEffect]="true">
            <ng-template #MostUsedGridBody let-MostUsedGridItem>
              <tr [routerLink]="MostUsedGridItem.route" (click)="closeModal()" class="search-result-row">
                <td *ngIf="!MostUsedGridItem.isFavorite" class="clickable-icon" (click)="addToFavorites($event, MostUsedGridItem.route)">
                  <i class="pi pi-star"></i>
                </td>
                <td *ngIf="MostUsedGridItem.isFavorite" class="clickable-icon" (click)="removeFromFavorites($event, MostUsedGridItem.route)">
                  <i class="pi pi-star-fill"></i>
                </td>
                <td>{{ MostUsedGridItem.primary }}</td>
                <td>{{ MostUsedGridItem.secondary }}</td>
                <td class="clickable-icon" (click)="removeFromMostUsed($event, MostUsedGridItem.route)">
                  <i class="pi pi-trash"></i>
                </td>
              </tr>
            </ng-template>
          </zappDataTable>
        </ng-template>
      </zapp-tabPanel>
    </zapp-tabView>
  </ng-template>
</zapp-modal>
