import {zAppDevMenuItem} from "@framework/components/MenuControl/menu-item";

export class MenuSearch {
  private _index: MenuSearchItem[];

  public constructor() {
  }

  public setMenuItems(items: zAppDevMenuItem[]) {
    this.makeSearchIndex(items);
  }

  private normalize(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  private split(text: string) {
    return text.split(/[\s\/]+/);
  }

  private normalizeAndSplit(text: string): string[] {
    return this.split(this.normalize(text));
  }

  private makeSearchIndex(items: zAppDevMenuItem[]) {
    this._index = [];
    this.makeSearchIndexWithPath(items, "");
  }

  private makeSearchIndexWithPath(items: zAppDevMenuItem[], path: string) {
    for (let item of items) {
      if (item.route) {
        this._index.push({
          text: item.label(),
          path: path,
          searchTerms: this.normalizeAndSplit(item.label() + " " + path),
          routerLink: item.route
        });
      }
      if (item.children) {
        this.makeSearchIndexWithPath(item.children, path + "/" + item.label());
      }
    }
  }

  search(query: string): MenuSearchItem[] {
    const results: MenuSearchItem[] = [];
    const queryTerms = this.normalizeAndSplit(query);
    for (let item of this._index) {
      for (let term of queryTerms) {
        if (item.searchTerms.some(searchTerm => searchTerm.includes(term))) {
          results.push(item);
          break;
        }
      }
    }
    return results;
  }
}

export interface MenuSearchItem {
  text: string,          // The label of the menu item
  path: string,          // The path of the menu item
  searchTerms: string[], // The search terms that correspond to this menu item
  routerLink: string     // The router link of the menu item
}
