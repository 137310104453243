<input pInputText *ngIf="!hidden" #Input
       [maxLength]="maxLength ?? 100000"
       [class]="hostClasses"
       [placeholder]="placeholder"
       [value]="_value"
       (change)="valueChangedEvent($event)"
       type='text'
       [readonly]="readonly"
       [class.zapp-required]="required"
       [disabled]="disabled || readonly" />
