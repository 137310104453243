import { Directive, TemplateRef, ViewContainerRef, Input, OnInit, AfterContentInit, OnChanges, SimpleChanges, Renderer2, ElementRef, InjectionToken, Optional, Inject } from '@angular/core';
import { EvaluationTimes } from '../models/enums/evaluation-times';
import { RuleActions } from '../models/enums/actions';
import { ConditionalFormatting, ConditionalFormattingData } from '../models/conditional-formatting';
import { RuleAction } from '../models/interfaces/irule-options';
import { CodeHelper } from 'src/app/@core/services/CodeHelper.service';
import { zAppDevComponentStatus } from '../../components/component-status';
import { BehaviorSubject } from 'rxjs';
import { CF_COMPONENT, IConditionalFormattingComponent } from './condition-formatting.directive';
import { IValidationItem } from '../../interfaces/validation-item.interface';
import { DataValidationMessageType } from '../models/enums/data-validation-message-type';

export class DataValidationData {
  dataValidations: IValidationItem[];
  model: any;
  controlName: string;
  context: string[];
}

@Directive({
  selector: '[zappDvdataValidaion]'
})
export class DataValidationDirective implements OnInit {

  private _model: any;
  private _context: any;
  private _controlName: string;
  private _dataValidations: IValidationItem[];
  private _component: IConditionalFormattingComponent;

  constructor(
    //private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private rendered: Renderer2,
    private element: ElementRef,
    private codeHelper: CodeHelper,
    @Optional() @Inject(CF_COMPONENT) component: IConditionalFormattingComponent | null) {

    if (component?._elementRef != null && component?._elementRef?.nativeElement === element.nativeElement) {
      this._component = component;
    }

  }

  ngOnInit(): void {


  }

  @Input()
  zappRulesAllControls: any;

  @Input()
  set zappDvdataValidaion(data: DataValidationData) {
    this._dataValidations = data.dataValidations;
    this._context = data.context;
    this._model = data.model;
    this._controlName = data.controlName;

    this.executeOnChangeRules();
  }

  executeOnLoadRules() {
    this.executeRules(EvaluationTimes.OnLoad);
  }

  executeOnChangeRules() {
    this.executeRules(EvaluationTimes.OnChange);
  }

  executeRules(evaluationTime: EvaluationTimes): boolean {

    if (this._component == null) {
      return;
    }

    const isValid = !this._dataValidations.some(dv => dv != null && !dv.isValid);
    let status: zAppDevComponentStatus = 'global';

    if (!isValid) {
      const messageType = this.getMessageType();

      switch (messageType) {
        case DataValidationMessageType.ERROR:
          status = 'danger';
          break;
        case DataValidationMessageType.WARN:
          status = 'warning';
          break;
        case DataValidationMessageType.INFO:
          status = 'info';
          break;
      }

      this._component.setStatus(status);
    } else {
      this._component.setStatus(status);
    }

  }

  private getMessageType(): DataValidationMessageType {

    const invalidRuleMessageTypes = this._dataValidations.filter(dv => !dv.isValid).map(r => r.messageType);

    if (invalidRuleMessageTypes.some(mt => mt === DataValidationMessageType.ERROR)) {
      return DataValidationMessageType.ERROR;
    }

    if (invalidRuleMessageTypes.some(mt => mt === DataValidationMessageType.WARN)) {
      return DataValidationMessageType.WARN;
    }

    return DataValidationMessageType.INFO;
  }

}
