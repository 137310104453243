import {
  Component,
  Input,
  ElementRef, ViewChild,
} from '@angular/core';
import {Subject} from 'rxjs';

import { HostListener } from '@angular/core';

import {IThemeSevice} from '../../theme/interfaces/theme.interface';
import {CF_COMPONENT} from '@framework/rule-engine/directives/condition-formatting.directive';
import {zAppDevBaseComponent} from '../BaseComponent/base.component';
import {PermissionService} from '../../security/services/permission.service';
import {zAppDevMenuItem} from "@framework/components/MenuControl/menu-item";
import {AuthService} from "@services/Auth.service";
import {ZAppDevModalComponent} from "@framework/components/Modal/modal.component";
import {IndexSearchResult, IndexSearchService} from "@services/IndexSearch.service";
import {MenuSearch} from "@framework/components/SearchModal/menu_search";
import {MenuFavorites} from "@framework/components/SearchModal/menu_favorites";
import {zAppDevTextBoxComponent} from "@framework/components/TextBox/textbox.component";
import {environment} from 'src/environments/environment';
import {zAppDevTabViewComponent} from "@framework/components/TabContainer/tabView.component";

@Component({
  selector: 'zapp-searchmodal',
  styleUrls: ['./SearchModal.component.less'],
  templateUrl: './SearchModal.component.html',
  providers: [{provide: CF_COMPONENT, useExisting: zAppSearchModalComponent}]
})
export class zAppSearchModalComponent extends zAppDevBaseComponent {
  protected destroy$ = new Subject<void>();

  @ViewChild('Modal')
  modal: ZAppDevModalComponent;

  @ViewChild('searchBox')
  searchBox: zAppDevTextBoxComponent;

  @ViewChild('tabView')
  tabView: zAppDevTabViewComponent;

  private _menuItems: zAppDevMenuItem[];
  @Input() set menuItems(value: any) {
    this._menuItems = value;
    this.menuSearch.setMenuItems(value);
  }

  @Input() indexes: string[] = [];         // which indexes to show in the search results
  @Input() showFavorites: boolean = true;  // whether to show the favorites section
  @Input() showMostUsed: boolean = true;   // whether to show the most used section
  @Input() showMenuSearch: boolean = true; // whether to show the menu search section

  @Input() menuName: string;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.code === 'KeyP') {
      event.preventDefault();
      this.openModal();
    }
  }

  searchCounter: number = 0;

  query: string = '';
  matchCase: boolean = false;
  partialMatch: boolean = true;

  menuSearch: MenuSearch = new MenuSearch();

  indexSearchResults: IndexSearchResult[] = [];
  menuSearchResults: MenuSearchResultItem[] = [];
  favoriteItems: FavoriteItem[] = [];
  mostUsedItems: MostUsedItem[] = [];

  constructor(
    protected themeService: IThemeSevice,
    protected readonly permissionsService: PermissionService,
    protected readonly authService: AuthService,
    protected readonly indexSearchService: IndexSearchService,
    protected readonly menuFavorites: MenuFavorites,
    protected elementRef: ElementRef
  ) {
    super(elementRef);
    this.options = themeService.getBreadcrumbThemeOptions();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.menuFavorites.fetchMenu(this.menuName).then(r => {
      this.updateFavorites();
      this.updateMostUsedItems();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModal() {
    this.modal.showDialog();
    // Focus and select the search box
    // This is a weird way to do it but doing it immediately doesn't work
    const showDialogInterval = setInterval(() => {
      if (this.searchBox) {
        this.searchBox.InputElement.nativeElement.focus();
        this.searchBox.InputElement.nativeElement.select();
        clearInterval(showDialogInterval);
      }
    }, 100);
    // Open last active tab
    // This is obviously also a weird way to do it
    const lastActiveTabInterval = setInterval(() => {
      if (this.tabView) {
        const lastActiveTab = this.getLastActiveTab();
        this.tabView.tabs.forEach((tab, index) => {
          tab.selected = (index === lastActiveTab);
        });
        if (this.tabView.tabs.every(tab => !tab.selected)) {
          this.tabView.tabs[0].selected = true;
          this.setLastActiveTab(0);
        }
        clearInterval(lastActiveTabInterval);
      }
    }, 100);
  }

  closeModal() {
    this.modal.hideDialog();
  }

  async search() {
    if (!this.query) {
      this.indexSearchResults = [];
      this.menuSearchResults = [];
      return;
    }
    this.searchCounter++;
    const localSearchCounter = this.searchCounter;
    const localIndexSearchResults = await this.searchIndexes();
    const localMenuSearchResults = await this.searchMenu();
    if (localSearchCounter !== this.searchCounter) {
      return;
    }
    this.indexSearchResults = localIndexSearchResults;
    this.menuSearchResults = localMenuSearchResults;
  }

  async searchIndexes() {
    const results: IndexSearchResult[] = [];
    for (let index of this.indexes) {
      const indexResults =
        await this.indexSearchService.search(index, this.query, this.matchCase, this.partialMatch);
      results.push(...indexResults);
    }
    return results;
  }

  async searchMenu() {
    return this.menuSearch.search(this.query)
      .map(item => ({
        text: item.text,
        path: item.path,
        routerLink: item.routerLink,
        isFavorite: this.favoriteItems.some(f => f.route === item.routerLink)
      }));
  }

  /**
   * Search the menu recursively for an item that matches the given predicate.
   * Optionally specify the root item to start the search from (used for recursion).
   * Returns the tree path to the matched item from the top to bottom.
   * This method also exists in the breadcrumb component.
   */
  findMenuItem(predicate: (item: zAppDevMenuItem) => boolean, root?: zAppDevMenuItem): zAppDevMenuItem[] | null {
    if (!root) {
      root = {name: undefined, children: this._menuItems};
    }
    if (predicate(root)) {
      return [root];
    }
    if (!root.children) {
      return null;
    }
    for (let child of root.children) {
      const path = this.findMenuItem(predicate, child);
      if (path != null) {
        return [root, ...path];
      }
    }
    return null;
  }

  private lastActiveTabLocalStorageKey(): string {
    return `SearchModalActiveTab_${environment.appId}_${this.menuName}`;
  }

  getLastActiveTab(): number {
    const lastActiveTabString = localStorage.getItem(this.lastActiveTabLocalStorageKey());
    return parseInt(lastActiveTabString, 10);
  }

  setLastActiveTab(event: number) {
    localStorage.setItem(this.lastActiveTabLocalStorageKey(), event.toString());
  }

  async addToFavorites(event: Event, route: string) {
    event.preventDefault();
    event.stopPropagation();
    await this.menuFavorites.addToFavorites(this.menuName, route);
    this.updateFavorites();
    this.updateMostUsedItems();
    await this.search();
  }

  async removeFromFavorites(event: Event, route: string) {
    event.preventDefault();
    event.stopPropagation();
    await this.menuFavorites.removeFromFavorites(this.menuName, route);
    this.updateFavorites();
    this.updateMostUsedItems();
    await this.search();
  }

  async removeFromMostUsed(event: Event, route: string) {
    event.preventDefault();
    event.stopPropagation();
    await this.menuFavorites.removeMostUsed(route, this.menuName);
    this.updateMostUsedItems();
  }

  updateFavorites() {
    this.favoriteItems =
      this.menuFavorites.getFavorites(this.menuName)
        .map(route => {
          const path = this.findMenuItem(item => item.route === route);
          if (!path) {
            return null;
          }
          const primary = path[path.length - 1].label();
          const secondary = path.slice(1, -1).map(item => item.label()).join(' / ');
          return {primary, secondary, route};
        })
        .filter(item => !!item);
  }

  updateMostUsedItems() {
    this.mostUsedItems =
      this.menuFavorites.getTopMostUsed(this.menuName)
        .map(route => {
          const path = this.findMenuItem(item => item.route === route);
          if (!path) {
            return null;
          }
          const primary = path[path.length - 1].label();
          const secondary = path.slice(1, -1).map(item => item.label()).join(' / ');
          const isFavorite = this.favoriteItems.some(item => item.route === route);
          return {primary, secondary, route, isFavorite};
        })
        .filter(item => !!item);
  }
}

interface FavoriteItem {
  primary: string;
  secondary: string;
  route: string;
}

interface MostUsedItem {
  primary: string;
  secondary: string;
  route: string;
  isFavorite: boolean;
}

interface MenuSearchResultItem {
  text: string;
  path: string;
  routerLink: string;
  isFavorite: boolean;
}
