import { Component, ElementRef, EventEmitter, Input, OnInit, Output, HostBinding } from '@angular/core';
import { zAppDevComponentStatus } from '../component-status';
import { zAppDevComponentSize } from '../component-size';

import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import * as ThemeOptions from '../../theme/interfaces/options';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';
import { CF_COMPONENT } from '../../rule-engine/directives/condition-formatting.directive';

@Component({
  selector: 'zappCheckbox',
  templateUrl: './checkbox.component.html',
  providers: [{ provide: CF_COMPONENT, useExisting: zAppDevCheckBoxComponent }]
})
export class zAppDevCheckBoxComponent extends zAppDevBaseComponent {
  protected options: ThemeOptions.CheckBoxOptions;

  stateClass: string;
  containerClass: string;

  disabled: boolean = false;
  
  @Input() value: boolean;

  @Output() valueChange : EventEmitter<any> = new EventEmitter<any>();

  @Input() status: zAppDevComponentStatus = 'global';

  @Input() size: zAppDevComponentSize = 'med';

  @Input() variation: string = 'Standard';

  @Input() hasClickEvent: boolean = false;

  @Input() readonly: boolean = false;

  constructor(protected themeservice: IThemeSevice, protected elementRef: ElementRef) {
    super(elementRef);
    this.options = themeservice.getCheckBoxThemeOptions();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.statusClass();

    this.hostClasses = [this.class, this.statusClass(), this.sizeClass()].join(
      " "
    );
  }

  valueChangedEvent(event) {

    if (event != null) {
      event.stopPropagation();
    }

    if (this.disabled) {
      return;
    }
    this.valueChange.emit(this.value);
  }

  //handleClickEvent(event) {
  //  if(this.hasClickEvent == false) {
  //    event.stopPropagation();
  //  }
  //}

  handleLabelClickEvent() {
    if (this.disabled || this.readonly) {
      return;
    }
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  statusClass() {
    this.stateClass = this.options[this.variation].Classes.Roles[this.status];
    this.containerClass = this.options[this.variation].Classes.Global;
    return `zapp-checkbox ${this.options[this.variation].Classes.Roles[this.status]}`;
  }
}
